<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="-1 -1 28 28"
		height="24"
		width="24"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
	>
		<g id="bank--institution-saving-bank-payment-finance">
			<path
				id="Vector"
				d="M23.975714285714286 10.214508571428572H2.0242857142857145c-1.0399925714285716 0 -1.4857068571428573 -1.1328571428571428 -0.6685639999999999 -1.6714285714285715L12.331428571428571 1.3559297142857143C12.53408 1.2367234285714286 12.764904285714286 1.173862857142857 13 1.173862857142857c0.2351142857142857 0 0.4659385714285714 0.06286057142857143 0.6685714285714286 0.18206685714285714l10.975714285714286 7.187150285714286c0.8171428571428572 0.5385714285714286 0.37142857142857144 1.6714285714285715 -0.6685714285714286 1.6714285714285715Z"
			></path>
			<path
				id="Vector_2"
				d="M24.142857142857142 20.42857142857143H1.8571428571428572c-0.5128351428571428 0 -0.9285714285714286 0.4158142857142857 -0.9285714285714286 0.9285714285714286V24.142857142857142c0 0.5127571428571429 0.4157362857142857 0.9285714285714286 0.9285714285714286 0.9285714285714286h22.285714285714285c0.5127571428571429 0 0.9285714285714286 -0.4158142857142857 0.9285714285714286 -0.9285714285714286v-2.7857142857142856c0 -0.5127571428571429 -0.4158142857142857 -0.9285714285714286 -0.9285714285714286 -0.9285714285714286Z"
			></path>
			<path
				id="Vector_3"
				d="M3.7142857142857144 10.214285714285715V20.42857142857143"
			></path>
			<path
				id="Vector_4"
				d="M9.904718571428571 10.214285714285715V20.42857142857143"
			></path>
			<path
				id="Vector_5"
				d="M16.09528142857143 10.214285714285715V20.42857142857143"
			></path>
			<path
				id="Vector_6"
				d="M22.285714285714285 10.214285714285715V20.42857142857143"
			></path>
		</g>
	</svg>
</template>
