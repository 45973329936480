<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="-1 -1 28 28"
		height="24"
		width="24"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.5"
	>
		<g
			id="dollar-coin--accounting-billing-payment-cash-coin-currency-money-finance"
		>
			<path
				id="Vector"
				d="M13 25.071428571428573c6.666957142857143 0 12.071428571428571 -5.404471428571428 12.071428571428571 -12.071428571428571C25.071428571428573 6.3331357142857145 19.666957142857143 0.9285714285714286 13 0.9285714285714286 6.3331357142857145 0.9285714285714286 0.9285714285714286 6.3331357142857145 0.9285714285714286 13c0 6.666957142857143 5.404564285714286 12.071428571428571 12.071428571428571 12.071428571428571Z"
			></path>
			<path
				id="Vector 3"
				d="M16.160578571428573 9.698705714285715c-0.10869857142857144 -0.3075428571428572 -0.27643571428571434 -0.5872285714285714 -0.4895985714285714 -0.8253885714285715 -0.4534214285714286 -0.5065914285714286 -1.112317142857143 -0.8253885714285715 -1.8456842857142857 -0.8253885714285715H11.90865c-1.220737142857143 0 -2.210334285714286 0.9895971428571428 -2.210334285714286 2.210315714285714 0 1.0387185714285714 0.7232642857142858 1.937297142857143 1.7379885714285714 2.1592814285714286l2.918147142857143 0.6383371428571429c1.1367757142857144 0.24867142857142857 1.9470285714285716 1.2560600000000002 1.9470285714285716 2.419727142857143 0 1.3675628571428573 -1.1086214285714286 2.4770942857142857 -2.4761842857142855 2.4770942857142857H12.174500000000002c-1.0781457142857143 0 -1.9953514285714284 -0.6890557142857143 -2.3352828571428574 -1.6507957142857144"
			></path>
			<path id="Vector 2489" d="M13 8.047612857142857V5.571428571428571"></path>
			<path id="Vector 2490" d="M13 20.4282V17.95207142857143"></path>
		</g>
	</svg>
</template>
